<template>
  <div>
    <section>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-start">
          <template>
            <h2>{{ item.name }}</h2>
            <b-button
              v-if="!isDetail"
              @click="edit(item._id)"
              variant="warning"
              class="btn-icon ml-1"
              size="sm"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>
        </div>
      </div>
      <b-row v-if="item.ranks && item.ranks.length > 0" class="mt-1">
        <draggable :list="item.ranks" class="mb-1 cursor-move">
          <b-badge
            v-for="(rank, index) in item.ranks"
            :key="index"
            class="mr-25 col-1"
            style="min-width: 88px"
            @click="editRank(rank)"
          >
            <h5 style="white-space: normal" class="my-2 text-truncate-2 h5">
              {{ getRankName(rank.key_rank) }}
            </h5>
            <feather-icon
              v-if="isDetail"
              @click.stop="confirmDelete(rank)"
              class="icon"
              icon="XIcon"
            />
            <b-img
              class="w-100"
              style="border-radius: 13px"
              height="77.3"
              :src="rank.thumbnail.value"
              v-if="rank.thumbnail.value"
            />
            <b-img
              v-else
              :blank="true"
              blank-color="#ccc"
              style="border-radius: 13px"
              alt="placeholder"
              height="77.3"
              class="w-100"
            />
          </b-badge>
        </draggable>
      </b-row>
    </section>
  </div>
</template>

<script>
import RankCreateForm from "./RankCreateForm";
import draggable from "vuedraggable";

export default {
  components: {
    RankCreateForm,
    draggable,
  },
  props: {
    item: { type: Object, required: true },
    isDetail: { type: Boolean, default: false },
    ranks: { type: Array, required: true },
  },
  methods: {
    edit(id) {
      this.$router.push({ name: "badges-detail", params: { id: id } });
    },
    getRankName(key) {
      let findText = this.ranks.find((item) => item.value == key);
      return findText && findText.text;
    },
    editRank(rank) {
      this.$emit("openModal", rank);
    },
    deleteRank(rank) {
      let findRank = this.item.ranks.findIndex((i) => i._id == rank._id);
      if (findRank >= 0) {
        this.item.ranks.splice(findRank, 1);
      }
    },
    confirmDelete(rank) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteRank(rank);
          }
        });
    },
  },
};
</script>
<style scoped>
.icon {
  position: absolute;
  right: 15px;
  bottom: 65px;
  color: #e92f2f;
}
.badge-secondary {
  background-color: #f8f8f8;
  display: inline-block !important;
}
</style>
