<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Badges Detail"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <badges-card
        @openModal="openModal"
        :item="badges"
        :is-detail="true"
        :ranks="ranks"
      />
      <div class="text-center mt-1">
        <b-button pill variant="primary" @click="openModal(null)">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Add Rank</span>
        </b-button>
      </div>
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteBadges"
      :is-deleting="isDeleting"
    />
    <rank-create-form @addRank="addRank" ref="rankCreateForm" :levels="ranks" />
  </div>
</template>

<script>
import service from "../service";
import RankCreateForm from "./_components/RankCreateForm";
import BadgesCard from "./_components/BadgesCard";
import defaultRank from "../defaultRank";

export default {
  components: {
    RankCreateForm,
    BadgesCard,
  },
  data() {
    return {
      badges: { type: Object },
      isDeleting: false,
      isUpdating: false,
      ranks: defaultRank,
    };
  },
  computed: {
    badgesId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Badges List",
          to: { name: "badges-list" },
        },
      ];
      items.push({ text: "Badges Detail", active: true });
      return items;
    },
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      let res = await service.get({ id: this.badgesId });
      if (res) {
        this.badges = res;
      }
    },
    async openModal(rank = null) {
      this.$refs.rankCreateForm.openModal(rank);
    },
    async addRank(rank) {
      let findIndex = this.badges.ranks.findIndex(
        (item) => item.key_rank == rank.key_rank
      );
      if (findIndex >= 0) {
        this.badges.ranks[findIndex].progress_max = rank.progress_max;
      } else {
        this.badges.ranks.push(rank);
      }
    },
    async update() {
      this.isUpdating = true;
      await service.update({ data: JSON.stringify(this.badges) });
      this.isUpdating = false;
    },
    async deleteBadges() {},
  },
};
</script>
