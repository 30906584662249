<template>
  <div>
    <b-modal
      size="lg"
      ok-only
      ok-title="Save"
      centered
      title="Create Rank"
      v-model="isOpen"
      @ok="save"
    >
      <b-row>
        <b-col class="text-center" cols="6">
          <strong class="h6">Thumbnail</strong>
          <ui-component :component-data="thumbnail" class="border" />
        </b-col>
        <b-col cols="6">
          <b-form-group label="Rank">
            <b-form-radio-group
              v-model="key_rank"
              :options="levels"
              name="radio-rank"
              stacked
            />
          </b-form-group>
          <b-form-group label="Progress max">
            <b-form-input
              class="mb-3"
              type="number"
              v-model="progress_max"
              placeholder="Progress max"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    levels: { type: Array, required: true },
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
      key_rank: null,
      progress_max: null,
      thumbnail: {
        type: "image",
        value: null,
        meta: {
          alt: null,
          redirect_link: null,
        },
      },
    };
  },
  methods: {
    openModal(rank) {
      if (rank) {
        this.key_rank = rank.key_rank;
        this.thumbnail = rank.thumbnail;
        this.progress_max = rank.progress_max;
      } else {
        this.refreshData();
      }
      this.isOpen = true;
    },
    async save() {
      this.$emit("addRank", {
        key_rank: this.key_rank,
        thumbnail: this.thumbnail,
        progress_max: this.progress_max,
      });
    },
    refreshData() {
      this.key_rank = null;
      this.thumbnail = {
        type: "image",
        value: null,
        meta: {
          alt: null,
          redirect_link: null,
        },
      };
    },
  },
};
</script>
