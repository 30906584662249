export default [{
        text: 'Đồng',
        value: 'BRONZE'
    },
    {
        text: 'Bạc',
        value: 'SILVER'
    },
    {
        text: 'Vàng',
        value: 'GOLD'
    },
    {
        text: 'Kim cương',
        value: 'DIAMOND'
    },
    {
        text: 'Cao thủ',
        value: 'MASTER'
    },
    {
        text: 'Huyền thoại',
        value: 'LEGENDARY'
    },
    {
        text: 'Chiến thần',
        value: 'GOLD_OF_WAR'
    },
]